import React from "react"

interface Props {
  name: string
  number: string
}

const VoteReward = ({ name, number }: Props) => {
  return (
    <div className="flex items-center justify-between p-2 text-sm font-semibold uppercase sm:p-4 xl:p-6 bg-backgroundAlpha">
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="29"
          viewBox="0 0 29 29"
        >
          <g
            id="Rectangle_43"
            data-name="Rectangle 43"
            fill="none"
            stroke="#939393"
            stroke-width="4"
          >
            <rect width="29" height="29" stroke="none" />
            <rect x="2" y="2" width="25" height="25" fill="none" />
          </g>
        </svg>

        <p className="ml-3 opacity-40">{name}</p>
      </div>
      <span
        className="opacity-80"
        dangerouslySetInnerHTML={{ __html: number }}
      ></span>
    </div>
  )
}

export default VoteReward
