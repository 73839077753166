import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
interface Props {
    title?: string;
    description?: string;
    image?: string;
    imageAlt?: string;
    article?: boolean;
    articleDatetime?: string;
    articleModifiedTime?: string;
    articleAuthor?: [];
}

const SEO = ({ article = false, ...props }: Props) => {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        defaultImage,
        defaultImageAlt,
    } = site.siteMetadata;

    const seo = {
        title: props.title || defaultTitle,
        description: props.description || defaultDescription,
        image: props.image || defaultImage,
        url: `${siteUrl}${pathname}`,
        imageAlt: props.imageAlt || defaultImageAlt,
    };

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate} defer={false}>
            <html lang="en" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta property="og:site_name" content="SpaceDelta" />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) ? (
                <meta property="og:type" content="article" />
            ) : (
                <meta property="og:type" content="website" />
            )}

            {/* Article optimizations */}

            {article && (
                <meta
                    property="og:published_time"
                    content={props.articleDatetime}
                />
            )}
            {article && (
                <meta
                    property="og:modified_time"
                    content={props.articleModifiedTime}
                />
            )}
            {article &&
                props.articleAuthor?.map((author) => (
                    <meta property="og:author" content={author.name} />
                ))}

            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {/* Image */}
            <meta property="og:image" content={seo.image} />
            <meta property="og:image:alt" content={seo.imageAlt} />

            <meta
                name="twitter:card"
                content={article ? "summary_large_image" : "summary"}
            />
            {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    );
};

export default SEO;

const query = graphql`
    {
        site {
            siteMetadata {
                defaultTitle
                titleTemplate
                defaultDescription
                defaultImageAlt
                siteUrl
                defaultImage
            }
        }
    }
`;
