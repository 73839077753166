import React from "react"

import { motion } from "framer-motion"

interface Props {
  url: string
  name: string
}

const VoteLink = ({ url, name }: Props) => {
  return (
    <div>
      <motion.a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        className="block p-4 transition-all lg:text-lg ease-easeInOutQuint xl:w-10/12 xl:p-6 bg-backgroundAlpha hover:text-purple-600 focus:text-purple-600"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {name}
      </motion.a>
    </div>
  )
}

export default VoteLink
