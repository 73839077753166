import React, { ReactElement } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { motion } from "framer-motion";

// Component imports
import Layout from "../components/Layout";
import BackgroundImage from "gatsby-background-image";
import Text from "../components/Text";
import Section from "../components/Section";
import VoteLink from "../components/VoteLink";
import VoteReward from "../components/VoteReward";
import SEO from "../components/SEO";

function Vote(): ReactElement {
    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "header_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            page_bg: file(relativePath: { eq: "page_bg.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            rewardTiers: allGhostPost(
                filter: { tags: { elemMatch: { slug: { eq: "rewards" } } } }
            ) {
                edges {
                    node {
                        title
                        html
                    }
                }
            }
        }
    `);

    const pageBg = [
        `linear-gradient(hsla(0, 0%, 6%, .95), hsla(0, 0%, 6%, .95))`,

        data.page_bg.childImageSharp.fluid,
    ];
    console.log(data);
    return (
        <Layout>
            <SEO
                title="Vote"
                description="Official list of vote links for SpaceDelta network. Vote for us and receive incredible rewards!"
            />
            {/* Votes Header */}
            <section className="h-newsHeaderSmall">
                <BackgroundImage
                    fluid={data.background.childImageSharp.fluid}
                    className="flex items-center justify-center h-full bg-center bg-cover"
                >
                    <motion.div
                        className="relative block py-5 text-center bg-backgroundAlpha md:py-10 xl:py-16"
                        initial={{ width: 0, scale: 1.5, opacity: 0 }}
                        animate={{ width: "100%", scale: 1, opacity: 1 }}
                        transition={{ delay: 0.2, duration: 0.3 }}
                    >
                        <Text variant="h2">Votes</Text>
                        <div
                            className="absolute z-10 border-2 border-gray-500 votesHeaderBox"
                            style={{
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        ></div>
                    </motion.div>
                </BackgroundImage>
            </section>

            {/* Votes Body */}
            <BackgroundImage fluid={pageBg} className="bg-repeat">
                <Section className="md:grid md:grid-cols-2 md:gap-4 xl:gap-16">
                    <div>
                        <Text variant="p" className="mb-8 lg:mb-7">
                            Click on each of the following links to be taken to
                            all of SpaceDelta's voting sites. Enter your
                            username and redeem your rewards for each site,
                            which are available every 24h.
                        </Text>

                        {/* Voting Website Links */}
                        <div>
                            <Text
                                variant="p"
                                className="mb-2 text-xs font-semibold uppercase lg:text-sm xl:transform xl:w-32 xl:-rotate-90 xl:-translate-x-32 xl:translate-y-12 xl:mb-0"
                            >
                                - Vote Links -
                            </Text>
                            <div className="grid gap-3">
                                <VoteLink
                                    url="https://minecraftservers.org/vote/559852"
                                    name="Minecraft Servers"
                                />
                                <VoteLink
                                    url="https://minecraft-server-list.com/server/479921/vote/"
                                    name="Minecraft Server List"
                                />
                                <VoteLink
                                    url="https://minecraft-mp.com/server/229676/vote/"
                                    name="Minecraft - MP"
                                />
                            </div>
                        </div>
                    </div>
                    {/* Divider */}
                    <div className="md:hidden">
                        <hr className="block my-12 bg-gray-500" />
                    </div>
                    {/* Voting Rewards */}
                    <div className="md:border-l md:border-gray-500 md:pl-4 xl:pl-10">
                        <Text variant="p" className="mb-8 lg:mb-12">
                            Voting enough times lets you claim a milestone
                            reward. Check out the milestones here!
                        </Text>

                        <Text
                            variant="p"
                            className="mb-2 text-xs font-semibold uppercase xl:transform xl:w-40 xl:-rotate-90 xl:-translate-x-40 xl:translate-y-16 xl:mb-0 lg:hidden"
                        >
                            - Monthly Rewards -
                        </Text>

                        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2">
                            {data.rewardTiers.edges.map(
                                (edge: any, i: number) => (
                                    <VoteReward
                                        key={i}
                                        name={edge.node.title}
                                        number={edge.node.html}
                                    />
                                )
                            )}
                        </div>
                    </div>
                </Section>
            </BackgroundImage>
        </Layout>
    );
}

export default Vote;
